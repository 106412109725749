import { useContext, useEffect } from 'react';
import UserContext from '../contexts/UserContext';
import { useNavigate } from 'react-router';

const ProtectedRoute = ({ children }) => {
  const { loggedInUser } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {}, []);

  if (loggedInUser.role !== 'administrator') {
    navigate('/home-page');
  }

  return children;
};

export default ProtectedRoute;
